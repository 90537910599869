import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dimensionality Reduction`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/dimension-reduction",
          "title": "dimension reduction"
        }}>{`dimension reduction`}</a>{` algorithms are able to take data from high `}<a parentName="li" {...{
          "href": "/dimensional",
          "title": "dimensional"
        }}>{`dimensional`}</a>{` spaces and project them into lower `}<a parentName="li" {...{
          "href": "/dimension",
          "title": "dimension"
        }}>{`dimension`}</a>{` spaces for projection high dimensional data to a lower dimensional space in such a way as to preserve as much of the structure of the data as possible.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      